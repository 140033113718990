import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEn from './en/translation.json';
import translationDe from './de/translation.json';

const DEFAULT_LANGUAGE = 'en';

const savedLanguage = localStorage.getItem('language');
if (!savedLanguage) {
  localStorage.setItem('language', DEFAULT_LANGUAGE);
}

i18next.use(initReactI18next).init({
  lng: localStorage.getItem('language')!,
  resources: {
    en: { translation: translationEn },
    de: { translation: translationDe }
  },
  fallbackLng: DEFAULT_LANGUAGE,
  // debug: window.location.origin.indexOf('localhost') !== -1,
  debug: false,
  keySeparator: '.',
  interpolation: { escapeValue: false },
  react: { useSuspense: true }
});
