import { Suspense, lazy } from 'react';
import { createRoot } from 'react-dom/client';
import './i18n/config';

const HorizonNew = lazy(() => import('./indexNew'));

fetch('/_rtconfig.json', {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  }
}).then(async (response: Response) => {
  const config = await response.json();
  localStorage.setItem('config', JSON.stringify(config));

  const container = document.getElementById('app');
  const root = createRoot(container!);

  root.render(
    <Suspense fallback="">
      <HorizonNew />
    </Suspense>
  );
});
